import mixpanel from 'mixpanel-browser';
import { useEffect, useRef } from 'react';

class Tracking {
  private isInitialized = false;

  init() {
    const mixpanelToken = (window as any).env?.MIXPANEL_TOKEN;

    if (mixpanelToken && mixpanelToken.length > 0) {
      mixpanel.init(mixpanelToken, { track_pageview: false, debug: process.env.NODE_ENV !== 'production' });
      this.isInitialized = true;
    }
  }

  private safeTrack(event: string, properties?: Record<string, any>) {
    if (this.isInitialized) {
      mixpanel.track(event, properties);
    }
  }

  trackLogin(userId: string) {
    if (this.isInitialized) {
      mixpanel.identify(userId);
    }
  }

  trackLogout() {
    if (this.isInitialized) {
      mixpanel.reset();
    }
  }

  trackRoute(pathname: string) {
    const routesMap = {
      '/': 'dashboard',
      '/sessions': 'archive',
      '/analyze': 'analytics',
      '/join-session': 'join_case',
      '/add-account': 'add_account',
      '/settings': 'settings',
      '/profile': 'profile',
      '/sign-in': 'login',
      '/sign-up': 'register',
      '/forgot-password': 'forgot_password',
      '/organization-profile': 'organization_profile',
      '/terms-of-use': 'terms_of_use',
      '/privacy-policy': 'privacy_policy',
    };

    const pageName = routesMap[pathname as keyof typeof routesMap];

    if (pageName) {
      this.safeTrack('screen_viewed', { screen_name: pageName });
    }
  }

  trackScreen(
    pageName: string,
    {
      session,
    }: {
      session?: {
        product: string;
        organ: string;
        organPosition?: string;
        donationType: string;
        sessionType: string;
      };
    },
  ) {
    this.safeTrack('screen_viewed', {
      screen_name: pageName,
      ...(session && {
        product: session.product,
        organ: session.organ,
        organ_position: session.organPosition,
        donation_type: session.donationType,
        session_type: session.sessionType,
      }),
    });
  }

  trackEvent(event: string, properties?: Record<string, any>) {
    this.safeTrack(event, properties);
  }
}

const tracking = new Tracking();

export default tracking;

// Custom hook for tracking page views
export const useTrackScreen = (
  pageName: string,
  session?: {
    product: string;
    organ: string;
    organPosition?: string;
    donationType: string;
    sessionType: string;
  },
) => {
  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      tracking.trackScreen(pageName, { session });
      hasTracked.current = true;
    }
  }, [pageName, session]);
};

export const useTrackRoute = (pathname: string) => {
  const lastTrackedPath = useRef<string | null>(null);

  useEffect(() => {
    // Only track if this is a new path or the first time
    if (lastTrackedPath.current !== pathname) {
      tracking.trackRoute(pathname);
      lastTrackedPath.current = pathname;
    }
  }, [pathname]);
};

export const useTrackIdentity = (userId: string | null) => {
  useEffect(() => {
    if (userId) {
      tracking.trackLogin(userId);
    } else {
      tracking.trackLogout();
    }
  }, [userId]);
};
